import { Contribution } from "../../types/api-types";

interface CamaradeContributionsProps {
  contributions: Contribution[];
  isLoading: boolean;
}

export const CamaradeContributions: React.FC<CamaradeContributionsProps> = ({ 
  contributions, 
  isLoading 
}) => {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-6">Contributions</h1>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <p>Chargement...</p>
        </div>
      ) : (
        <div className="space-y-6">
          {contributions.length === 0 ? (
            <p>Pas de contributions disponibles</p>
          ) : (
            contributions.map((contribution) => (
              <div key={contribution.id} className="bg-white shadow rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-2">{contribution.title}</h3>
                <div className="space-y-2">
                  <p className="text-gray-600">{contribution.description}</p>
                  <div className="text-sm text-gray-500">
                    <p>Par: {contribution.camaradeName}</p>
                    <p>Date: {new Date(contribution.datePublication).toLocaleDateString()}</p>
                    {contribution.link && (
                      <a 
                        href={contribution.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Lien : {contribution.link}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}; 