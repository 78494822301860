import { PageLayout } from "../components/page-layout";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getPresses } from "../services/presse.service";
import { Presse } from "../types/api-types";

export const PressePage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [presses, setPresses] = useState<Presse[]>([]);

  useEffect(() => {
    const fetchPresses = async () => {
      try {
        const { data, error } = await getPresses();
        
        if (error) {
          console.error("Error fetching presses:", error);
          return;
        }

        setPresses(data as unknown as Presse[]);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchPresses();
  }, [getAccessTokenSilently]);

  return (
    <PageLayout>
      <div className="content-page">
        <h1 className="page-title page-title-with-accent">Presse</h1>
        <div>
          {presses.map((presse) => (
            <p key={presse.id}>
              <a className="underlined" href={presse.lien}>
                <b>{presse.lien}</b>
              </a>
              <br />
              {new Date(presse.date).toLocaleDateString('fr-FR')} - {presse.description}
              <br /><br />
            </p>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};
