import { PageLayout } from "../components/page-layout";
import { useState, useEffect } from "react";
import { Activite } from "../types/api-types";
import { getFutureActivites, getPastActivites } from "../services/activites.service";

export const PresentationPage = () => {
  const [upcomingEvents, setUpcomingEvents] = useState<Activite[]>([]);
  const [recentNews, setRecentNews] = useState<Activite[]>([]);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const [futureRes, pastRes] = await Promise.all([
          getFutureActivites(),
          getPastActivites()
        ]);

        if (futureRes.data) {
          setUpcomingEvents((futureRes.data as unknown as Activite[]).slice(0, 2));
        }
        if (pastRes.data) {
          setRecentNews((pastRes.data as unknown as Activite[]).slice(0, 2));
        }
      } catch (err) {
        console.error('Failed to load content:', err);
      }
    };

    loadContent();
  }, []);

  return (
    <PageLayout>
      <div className="content-page">
        <h1 className="page-title">Qui sommes-nous ?</h1>
        <div className="bg-logo">
          <p className="mb-8">
            L'Amicale des Ingénieurs des Ponts et Chaussées du Maroc « Ponts Maroc »
            réunit les anciens élèves marocains ayant obtenu le diplôme d'ingénieur de
            l'Ecole Nationale des Ponts et Chaussées. Elle est aujourd'hui l'une
            des plus importantes associations d'anciens élèves des grandes écoles
            françaises et compte environ 500 membres. Ponts Maroc organise différents
            évènements parmi lesquels des conférences thématiques, des colloques et
            des visites de sites. L'orientation générale est de contribuer au
            développement du pays.
          </p>
          <p>
            L'association s'est enrichie au cours des années de la diversité des profils et des parcours et continue de se réformer avec l'arrivée de ses jeunes membres. Forte de ces profils d'excellence qui se sont orientés tant vers le secteur privé que vers le secteur public et la gouvernance, l'association Ponts Maroc aspire, à travers ses actions, à renforcer les liens entre nouveaux et anciens diplômés.
          </p>
        </div>

        <div className="mt-12">
          <h2 className="text-3xl font-bold mb-6">Prochains événements</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {upcomingEvents.map((event) => (
              <article key={event.id} className="article-card">
                <img
                  src={`${process.env.REACT_APP_API_SERVER_URL}${event.mainImage}`}
                  alt={event.title}
                  className="article-image"
                />
                <div className="article-content">
                  <p className="font-bold">{event.title}</p>
                  <p>{event.shortDescription}</p>
                </div>
              </article>
            ))}
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-3xl font-bold mb-6">Dernières actualités</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {recentNews.map((news) => (
              <article key={news.id} className="article-card">
                <img
                  src={`${process.env.REACT_APP_API_SERVER_URL}${news.mainImage}`}
                  alt={news.title}
                  className="article-image"
                />
                <div className="article-content">
                  <p className="font-bold">{news.title}</p>
                  <p>{news.shortDescription}</p>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
