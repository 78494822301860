import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Job } from "../../types/api-types";
import { createJob, getJobs, deleteJob } from "../../services/jobs.service";

export const AdminJobs: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    datePublication: new Date().toISOString().split('T')[0],
    camaradeReferent: ''
  });

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const { data, error } = await getJobs();
        if (!error && data) {
          setJobs(data as unknown as Job[]);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { error } = await deleteJob(accessToken, id);
      
      if (!error) {
        setJobs(prev => prev.filter(job => job.id !== id));
      } else {
        console.error('Error deleting job:', error);
      }
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      const jobData = {
        title: formData.title,
        description: formData.description,
        datePublication: new Date(formData.datePublication).toISOString(),
        camaradeReferent: formData.camaradeReferent
      };

      const { data, error } = await createJob(accessToken, jobData);
      if (!error && data) {
        setJobs(prev => [...prev, data as unknown as Job]);
        setShowForm(false);
        setFormData({
          title: '',
          description: '',
          datePublication: new Date().toISOString().split('T')[0],
          camaradeReferent: ''
        });
      }
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="tab-pane">
      <h2>Gestion des Offres d'Emploi</h2>
      <button 
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
        onClick={() => setShowForm(true)}
      >
        + Ajouter une offre d'emploi
      </button>
      
      {showForm && (
        <form className="admin-form" onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="title">Titre du poste</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
              rows={4}
            />
          </div>

          <div className="form-group">
            <label htmlFor="datePublication">Date de publication</label>
            <input
              type="date"
              id="datePublication"
              name="datePublication"
              value={formData.datePublication}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="camaradeReferent">Camarade référent</label>
            <input
              type="text"
              id="camaradeReferent"
              name="camaradeReferent"
              value={formData.camaradeReferent}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="flex gap-2 mt-4">
            <button 
              type="submit" 
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
            >
              Sauvegarder
            </button>
            <button 
              type="button" 
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 font-medium transition-colors"
              onClick={() => setShowForm(false)}
            >
              Annuler
            </button>
          </div>
        </form>
      )}

      <div className="items-list">
        {jobs.map(job => (
          <div key={job.id} className="admin-item">
            <h3>{job.title}</h3>
            <p className="job-description">{job.description}</p>
            <p className="job-details">
              Référent: {job.camaradeReferent}
            </p>
            <p className="job-date">
              Publié le: {new Date(job.datePublication).toLocaleDateString()}
            </p>
            <button 
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 font-medium transition-colors"
              onClick={() => handleDelete(job.id)}
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
