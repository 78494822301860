import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Activite } from "../../types/api-types";
import { createActivite, getPastActivites, getFutureActivites, deleteActivite } from "../../services/activites.service";

export const AdminActivites: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [activites, setActivites] = useState<Activite[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    shortDescription: '',
    mainImage: '',
    date: '',
    content: '',
  });

  useEffect(() => {
    const fetchActivites = async () => {
      try {
        const [pastResponse, futureResponse] = await Promise.all([
          getPastActivites(),
          getFutureActivites()
        ]);

        const pastActivites = pastResponse.error ? [] : (pastResponse.data as unknown as Activite[]);
        const futureActivites = futureResponse.error ? [] : (futureResponse.data as unknown as Activite[]);
        
        setActivites([...futureActivites, ...pastActivites]);
      } catch (error) {
        console.error('Error fetching activites:', error);
      }
    };

    fetchActivites();
  }, [getAccessTokenSilently]);

  const handleDelete = async (id: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette actualité ?')) {
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently();
      const { error } = await deleteActivite(accessToken, id);
      
      if (!error) {
        setActivites(prev => prev.filter(activite => activite.id !== id));
      } else {
        console.error('Error deleting activite:', error);
      }
    } catch (error) {
      console.error('Error deleting activite:', error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      const formDataObj = new FormData();
      formDataObj.append('title', formData.title);
      formDataObj.append('short_description', formData.shortDescription);
      formDataObj.append('date', formData.date);
      formDataObj.append('content', formData.content);
      
      const fileInput = document.querySelector('input[name="main_image"]') as HTMLInputElement;
      if (fileInput?.files?.[0]) {
        formDataObj.append('main_image', fileInput.files[0]);
      }

      const { data, error } = await createActivite(accessToken, formDataObj);
      if (!error && data) {
        setActivites(prev => [...prev, data as unknown as Activite]);
        setShowForm(false);
      }
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  return (
    <div className="tab-pane">
      <h2>Gestion des Actualités</h2>
      <button 
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
        onClick={() => setShowForm(true)}
      >
        + Ajouter une actualité
      </button>
      {showForm && (
        <form className="admin-form" onSubmit={handleFormSubmit} encType="multipart/form-data">
          <div className="form-group">
            <label htmlFor="title">Titre</label>
            <input
              type="text"
              id="title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="shortDescription">Description courte</label>
            <textarea
              id="shortDescription"
              value={formData.shortDescription}
              onChange={(e) => setFormData({ ...formData, shortDescription: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="content">Contenu</label>
            <textarea
              id="content"
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })} 
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="datetime-local"
              id="date"
              value={formData.date}
              onChange={(e) => setFormData({ ...formData, date: e.target.value })}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="main_image">Image principale</label>
            <input
              type="file"
              id="main_image"
              name="main_image"
              accept="image/*"
              required
            />
          </div>

          <div className="flex gap-2 mt-4">
            <button 
              type="submit" 
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
            >
              Sauvegarder
            </button>
            <button 
              type="button" 
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 font-medium transition-colors"
              onClick={() => setShowForm(false)}
            >
              Annuler
            </button>
          </div>
        </form>
      )}
      <div className="items-list">
        {activites.map(activite => (
          <div key={activite.id} className="admin-item">
            {activite.mainImage && (
              <div className="admin-item-image">
                <img 
                  src={`${process.env.REACT_APP_API_SERVER_URL}${activite.mainImage}`}
                  alt={activite.title}
                  className="article-image"
                  height={200}
                />
              </div>
            )}
            <h3>{activite.title}</h3>
            <p>{activite.shortDescription}</p>
            <button 
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 font-medium transition-colors"
              onClick={() => handleDelete(activite.id)}
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}; 