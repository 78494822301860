import { PageLayout } from "../components/page-layout";
import { useState, useEffect } from "react";
import { getJobs } from "../services/jobs.service";
import { Job, Contribution } from "../types/api-types";
import { getContributions } from "../services/contributions.service";
import { CamaradeJobs } from "../components/camarade/camarade-jobs";
import { CamaradeContributions } from "../components/camarade/camarade-contributions";

export const CamaradesPage = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [contributions, setContributions] = useState<Contribution[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [jobsResponse, contributionsResponse] = await Promise.all([
          getJobs(),
          getContributions()
        ]);
        
        if (jobsResponse.data) {
          setJobs(jobsResponse.data as unknown as Job[]);
        }
        
        if (contributionsResponse.data) {
          setContributions(contributionsResponse.data as unknown as Contribution[]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <PageLayout>
      <div className="grid-layout__content">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <CamaradeJobs jobs={jobs} isLoading={isLoading} />
            <CamaradeContributions contributions={contributions} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
