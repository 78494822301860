import { PageLayout } from "../components/page-layout";
import { useState, useEffect } from "react";
import { ArticleDetail } from "../components/article-details";
import { Activite } from "../types/api-types";
import { getPastActivites } from "../services/activites.service";

export const ActualitesPage = () => {
  const [selectedArticleId, setSelectedArticleId] = useState<string | null>(null);
  const [articles, setArticles] = useState<Activite[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadActivites = async () => {
      try {
        const { data, error } = await getPastActivites();
        
        if (error) {
          setError(error.message);
          return;
        }

        setArticles((data as unknown) as Activite[] || []);
      } catch (err) {
        setError("Failed to load past activities");
        console.error(err);
      }
    };

    loadActivites();
  }, []);

  if (error) {
    return (
      <PageLayout>
        <div className="content-page">
          <h1>Actualités</h1>
          <p className="error-message">{error}</p>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className="content-page">
        <h1 className="page-title">Actualités</h1>
        {selectedArticleId ? (
          <>
            <button 
              className="back-button" 
              onClick={() => setSelectedArticleId(null)}
            >
              ← Retour aux actualités
            </button>
            <ArticleDetail articleId={selectedArticleId} />
          </>
        ) : (
          <div className="articles-grid">
            {articles.map((article) => (
              <article 
                key={article.id} 
                className="article-card"
                onClick={() => setSelectedArticleId(article.id)}
              >
                <img
                  src={`${process.env.REACT_APP_API_SERVER_URL}${article.mainImage}`}
                  alt={article.title}
                  className="article-image"
                />
                <div className="article-content">
                  <p className="font-bold">{article.title}</p>
                  <p>{article.shortDescription}</p>
                  <button className="read-more">
                    Lire la suite →
                  </button>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
    </PageLayout>
  );
};
