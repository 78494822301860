import React from "react";
import { MainPage } from "../components/main-page";
import { PageLayout } from "../components/page-layout";

export const HomePage: React.FC = () => (
  <PageLayout>
    <>
      <MainPage />
    </>
  </PageLayout>
);
