import React, { useState } from "react";
import { PageLayout } from "../components/page-layout";
import { AdminActivites } from "../components/admin/admin-activites";
import { AdminAnnuaire } from "../components/admin/admin-annuaire";
import { AdminNominations } from "../components/admin/admin-nominations";
import { AdminPresse } from "../components/admin/admin-presse";
import { AdminJobs } from "../components/admin/admin-jobs";
import { exportData, downloadStaticContent } from "../services/admin.service";
import { useAuth0 } from "@auth0/auth0-react";
import { AdminContributions } from "../components/admin/admin-contributions";

export const AdminPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("actualites");
  const { getAccessTokenSilently } = useAuth0();

  const handleExport = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await exportData(accessToken);
      
      if (error) {
        console.error('Export failed:', error);
        return;
      }

      // Create and trigger download
      const blob = new Blob([data as unknown as BlobPart], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'database_export.zip';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Export error:', error);
    }
  };

  const handleDownloadStatic = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await downloadStaticContent(accessToken);
      
      if (error || !data) {
        console.error('Download failed:', error);
        return;
      }

      // Create and trigger download
      const blob = new Blob([data as unknown as BlobPart], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'static_content.zip';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Admin Page
        </h1>

        

        <div className="content__body">
          {/* Tabs */}
          <div className="tabs">
            <button 
              className={`tab ${activeTab === 'actualites' ? 'active' : ''}`}
              onClick={() => setActiveTab('actualites')}
            >
              Actualités
            </button>
            <button 
              className={`tab ${activeTab === 'annuaire' ? 'active' : ''}`}
              onClick={() => setActiveTab('annuaire')}
            >
              Annuaire
            </button>
            <button 
              className={`tab ${activeTab === 'nominations' ? 'active' : ''}`}
              onClick={() => setActiveTab('nominations')}
            >
              Nominations
            </button>
            <button 
              className={`tab ${activeTab === 'jobs' ? 'active' : ''}`}
              onClick={() => setActiveTab('jobs')}
            >
              Emplois
            </button>
            <button 
              className={`tab ${activeTab === 'contributions' ? 'active' : ''}`}
              onClick={() => setActiveTab('contributions')}
            >
              Contributions
            </button>
          </div>

          {/* Tab Content */}
          <div className="tab-content">
            {activeTab === 'actualites' && <AdminActivites />}
            {activeTab === 'annuaire' && <AdminAnnuaire />}
            {activeTab === 'nominations' && <AdminNominations />}
            {activeTab === 'presse' && <AdminPresse />}
            {activeTab === 'jobs' && <AdminJobs />}
            {activeTab === 'contributions' && <AdminContributions />}
          </div>
        </div>

        {/* Admin Actions */}
        <div className="admin-actions">
          <button 
            onClick={handleExport}
            className="button admin-actions__button"
          >
            Export Data
          </button>
          <button 
            onClick={handleDownloadStatic}
            className="button admin-actions__button"
          >
            Download Static Content
          </button>
        </div>
      </div>
    </PageLayout>
  );
};
