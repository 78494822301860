import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getContributions, createContribution, deleteContribution } from '../../services/contributions.service';
import { Contribution } from '../../types/api-types';

export const AdminContributions: React.FC = () => {
  const [contributions, setContributions] = useState<Contribution[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    camaradeName: '',
    link: '',
  });

  const fetchContributions = async () => {
    try {
      const { data } = await getContributions();
      if (data) {
        setContributions(data as unknown as Contribution[]);
      }
    } catch (error) {
      console.error('Error fetching contributions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContributions();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      await createContribution(accessToken, {
        ...formData,
        datePublication: new Date().toISOString(),
      });
      fetchContributions();
      setFormData({ title: '', description: '', camaradeName: '', link: '' });
      setIsFormVisible(false);
    } catch (error) {
      console.error('Error creating contribution:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await deleteContribution(accessToken, id);
      fetchContributions();
    } catch (error) {
      console.error('Error deleting contribution:', error);
    }
  };

  return (
    <div className="tab-pane">
      <h2>Gérer les contributions</h2>
      <button 
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
        onClick={() => {
          setFormData({ title: '', description: '', camaradeName: '', link: '' });
          setIsFormVisible(true);
        }}
      >
        + Ajouter une contribution
      </button>
      
      {isFormVisible && (
        <form className="admin-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Titre</label>
            <input
              type="text"
              id="title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              required
              rows={4}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="camaradeName">Nom du camarade</label>
            <input
              type="text"
              id="camaradeName"
              value={formData.camaradeName}
              onChange={(e) => setFormData({ ...formData, camaradeName: e.target.value })}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="link">Lien (optionnel)</label>
            <input
              type="url"
              id="link"
              value={formData.link}
              onChange={(e) => setFormData({ ...formData, link: e.target.value })}
            />
          </div>
          
          <div className="flex gap-2 mt-4">
            <button 
              type="submit" 
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
            >
              Sauvegarder
            </button>
            <button 
              type="button" 
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 font-medium transition-colors"
              onClick={() => {
                setFormData({ title: '', description: '', camaradeName: '', link: '' });
                setIsFormVisible(false);
              }}
            >
              Annuler
            </button>
          </div>
        </form>
      )}

      {isLoading ? (
        <p>Chargement...</p>
      ) : (
        <div className="space-y-4 mt-6">
          {contributions.map((contribution) => (
            <div key={contribution.id} className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-3">{contribution.title}</h3>
              <p className="text-gray-700 mb-4 leading-relaxed">{contribution.description}</p>
              <p className="text-gray-600 mb-3">
                Par: <span className="font-medium">{contribution.camaradeName}</span>
              </p>
              {contribution.link && (
                <a 
                  href={contribution.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 inline-block mb-4 underline px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors mx-4"
                >
                  Voir le lien
                </a>
              )}
              <button 
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 font-medium transition-colors"
                onClick={() => handleDelete(contribution.id)}
              >
                Supprimer
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}; 