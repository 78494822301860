import { PageLayout } from "../components/page-layout";
import { useState, useEffect, useCallback } from "react";
import { Camarade } from "../types/api-types";
import { getCamarades, searchCamarades } from "../services/annuaire.service";
import { CamaradeListResponse } from "../models/api-response";
import debounce from 'lodash/debounce';


export const AnnuairePage = () => {
  const [members, setMembers] = useState<Camarade[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const itemsPerPage = 30;

  const fetchMembers = async (search: string = "") => {
    setIsLoading(true);
    try {
      const { data, error } = search
        ? await searchCamarades(search, currentPage * itemsPerPage, itemsPerPage)
        : await getCamarades(currentPage * itemsPerPage, itemsPerPage);
      
      if (error || !data) {
        console.error(error);
        return;
      }

      const response = data as unknown as CamaradeListResponse;
      setMembers(response.data as Camarade[]);
      setTotalMembers(response.total);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      fetchMembers(searchTerm);
    }, 2000),
    []
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      fetchMembers();
    }
    // Cleanup function to cancel pending debounced calls
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, currentPage]);

  const totalPages = Math.ceil(totalMembers / itemsPerPage);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsLoading(true); // Set loading immediately when typing
    debouncedSearch(e.target.value);
  };

  return (
    <PageLayout>
      <div className="grid-layout__content">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="page-title">Notre Annuaire</h1>
          
          <div className="mb-6">
            <input
              type="text"
              placeholder="Rechercher un membre..."
              className="search-input"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {isLoading && <span className="ml-2">Recherche en cours...</span>}
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <p>Chargement...</p>
            </div>
          ) : (
            <>
              <div className="members-grid gap-6 mb-8">
                {members.length === 0 ? (
                  <p>Pas de membres enregistrés</p>
                ) : (
                  members.map((member) => (
                    <div 
                      key={member.id} 
                      className="member-card"
                    >
                      <div className="member-info">
                        <h3>{member.name}</h3>
                        <div className="member-details">
                          <p>Promotion {member.promotionYear}</p>
                          <p>{member.currentSector}</p>
                          <p>{member.currentPosition}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>

              {/* Pagination Controls */}
              <div className="pagination-controls">
                <button
                  onClick={() => setCurrentPage(p => Math.max(0, p - 1))}
                  disabled={currentPage === 0 || isLoading}
                  className="pagination-button"
                >
                  Précédent
                </button>
                <span className="pagination-info">
                  Page {currentPage + 1} sur {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage(p => Math.min(totalPages - 1, p + 1))}
                  disabled={currentPage >= totalPages - 1 || isLoading}
                  className="pagination-button"
                >
                  Suivant
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
};
