import { useAuth0 } from "@auth0/auth0-react";

export const useAuthRoles = () => {
  const { isAuthenticated, user } = useAuth0();
  
  const userRoles = user?.['ponts/roles'] || [];
  
  const hasAdminRole = userRoles.includes('admin');
  const hasCamaradeRole = userRoles.includes('camarade');
  
  const canAccessCamaradeRoutes = isAuthenticated && (hasAdminRole || hasCamaradeRole);
  const canAccessAdminRoutes = isAuthenticated && hasAdminRole;

  return {
    isAuthenticated,
    canAccessCamaradeRoutes,
    canAccessAdminRoutes,
    roles: userRoles,
  };
};