import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { Nomination } from "../../types/api-types";
import { createNomination, getNominations, deleteNomination } from "../../services/nomination.service";

export const AdminNominations: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [nominations, setNominations] = useState<Nomination[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    position: '',
    date: '',
    description: '',
    promotion: 1950,
    photo: null as File | null
  });

  useEffect(() => {
    const fetchNominations = async () => {
      try {
        const { data, error } = await getNominations();
        if (!error && data) {
          setNominations(data as unknown as Nomination[]); 
        }
      } catch (error) {
        console.error('Error fetching nominations:', error);
      }
    };

    fetchNominations();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { error } = await deleteNomination(accessToken, id);
      if (!error) {
        setNominations(prev => prev.filter(nom => nom.id !== id));
      }
    } catch (error) {
      console.error('Error deleting nomination:', error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      const formDataObj = new FormData();
      formDataObj.append('name', formData.name);
      formDataObj.append('position', formData.position);
      formDataObj.append('date', formData.date);
      formDataObj.append('description', formData.description);
      formDataObj.append('promotion', formData.promotion.toString());
      
      if (formData.photo) {
        formDataObj.append('photo', formData.photo);
      }

      const { data, error } = await createNomination(accessToken, formDataObj);
      if (!error && data) {
        setNominations(prev => [...prev, data as unknown as Nomination]);
        setShowForm(false);
        // Reset form
        setFormData({
          name: '',
          position: '',
          date: '',
          description: '',
          promotion: 0,
          photo: null
        });
      }
    } catch (error) {
      console.error('Error saving nomination:', error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData(prev => ({
        ...prev,
        photo: e.target.files![0]
      }));
    }
  };

  return (
    <div className="tab-pane">
      <h2>Gestion des Nominations</h2>
      <button 
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
        onClick={() => setShowForm(true)}
      >
        + Ajouter une nomination
      </button>
      
      {showForm && (
        <form className="admin-form" onSubmit={handleFormSubmit} encType="multipart/form-data">
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="position">Poste</label>
            <input
              type="text"
              id="position"
              name="position"
              value={formData.position}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows={4}
            />
          </div>

          <div className="form-group">
            <label htmlFor="promotion">Promotion</label>
            <input
              type="number"
              id="promotion"
              name="promotion"
              value={formData.promotion}
              onChange={handleInputChange}
              min="1950"
              max={new Date().getFullYear()}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="photo">Photo</label>
            <input
              type="file"
              id="photo"
              name="photo"
              accept="image/*"
              onChange={handleFileChange}
              required
            />
          </div>

          <div className="flex gap-2 mt-4">
            <button 
              type="submit" 
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
            >
              Sauvegarder
            </button>
            <button 
              type="button" 
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 font-medium transition-colors"
              onClick={() => setShowForm(false)}
            >
              Annuler
            </button>
          </div>
        </form>
      )}

      <div className="space-y-4 mt-6">
        {nominations.map(nomination => (
          <div key={nomination.id} className="p-4 bg-white rounded-lg shadow">
            <div className="mb-4">
              <img 
                src={`${process.env.REACT_APP_API_SERVER_URL}${nomination.photo}`}
                alt={nomination.name}
                className="w-full h-48 object-cover rounded"
              />
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold">{nomination.name}</h3>
              <p className="text-gray-600">{nomination.position}</p>
              <p className="text-gray-500 text-sm">
                {new Date(nomination.date).toLocaleDateString()}
              </p>
              <p className="text-gray-700">{nomination.description}</p>
            </div>
            <button 
              className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 font-medium transition-colors"
              onClick={() => handleDelete(nomination.id)}
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}; 