import { Job } from "../../types/api-types";

interface CamaradeJobsProps {
  jobs: Job[];
  isLoading: boolean;
}

export const CamaradeJobs: React.FC<CamaradeJobsProps> = ({ jobs, isLoading }) => {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-6">Offres d'emploi</h1>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <p>Chargement...</p>
        </div>
      ) : (
        <div className="space-y-6">
          {jobs.length === 0 ? (
            <p>Pas d'offres d'emploi disponibles</p>
          ) : (
            jobs.map((job) => (
              <div key={job.id} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-200 rounded-lg p-6 border border-gray-100">
                <h3 className="text-xl font-semibold mb-3 text-gray-800">{job.title}</h3>
                <div className="space-y-3">
                  <p className="text-gray-600 leading-relaxed">{job.description}</p>
                  <div className="pt-4 border-t border-gray-100">
                    <p className="text-sm text-gray-500 flex items-center gap-2">
                      <span className="font-medium">Contact:</span> {job.camaradeReferent}
                    </p>
                    <p className="text-sm text-gray-500 flex items-center gap-2">
                      <span className="font-medium">Date de publication:</span> {new Date(job.datePublication).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}; 