import { PageLayout } from "../components/page-layout";

export const BureauAmicalePage = () => {
  return (
    <PageLayout>
      <div className="content-page bg-logo">
        <h1 className="page-title">Bureau de l'amicale</h1>
        
        <p>
          <b>Mohamed Amine Souissi</b> – Promo 2006 – Président<br />
          <b>Tarik DINIA</b> – Promo 2006 – Vice Président<br />
          <b>Maryam Guessous</b> – Promo 2013 – Secrétaire Général<br />
          <b>Mohamed El Habib CHENGUITI ANSARI</b> – Promo 2007 – Secrétaire Général Adjoint<br />
          <b>Reda Moukhli</b> – Promo 2007 – Trésorier<br />
          <b>Imad LAKIM</b> – Promo 2019 – Trésorier Adjoint<br />
          <b>El Yazid Zellou</b> – Promo 1980 – Assesseur<br />
          <b>Mohamed saâd El Alaoui</b> – Promo 1982 – Assesseur<br />
          <b>ABDELHAY BENOMAR</b> – Promo 1991 – Assesseur<br />
          <b>Mounia MAHLI</b> – Promo 2001 – Assesseur<br />
          <b>Mourad BENNIS</b> – Promo 2004 – Assesseur<br />
          <b>Amine BENNIS</b> – Promo 2011 – Assesseur<br />
          <b>Issam El Alaoui</b> – Promo 2011 – Assesseur
        </p>
      </div>
    </PageLayout>
  );
};
