import { PageLayout } from "../components/page-layout";

export const StatutsPage = () => {
  return (
    <PageLayout>
      <div className="content-page bg-logo">
        <div className="max-w-4xl mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-8 text-gray-900">Statuts Amicale des Ingénieurs des Ponts et Chaussées du Maroc</h1>

          <h2 className="text-2xl font-semibold mt-8 mb-6 text-gray-800">TITRE PREMIER Formation - Objet – Siège - Durée</h2>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE PREMIER : FORMATION</h3>
          <p className="text-gray-600 leading-relaxed mb-4">Il est constitué entre les adhérents aux présents statuts une Association dénommée « Amicale des Ingénieurs des Ponts et Chaussées du Maroc » dite « Ponts Maroc » régie par le Dahir du 03 joumada 1378 (15 novembre 1958).</p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE DEUXIEME: OBJET</h3>
          <p className="text-gray-600 leading-relaxed mb-4">L'Association a pour Objet :</p>
          <ul className="list-disc pl-8 mb-4 text-gray-600">
              <li className="mb-2">de maintenir et de développer entre ses adhérents des relations amicales et culturelles ;</li>
              <li className="mb-2">de favoriser et d'encourager toute œuvre de solidarité et d'aide mutuelle pour eux, leurs veuves et leurs enfants ;</li>
              <li className="mb-2">d'œuvrer en faveur de la formation des cadres techniques du pays.</li>
          </ul>
          <p className="text-gray-600 leading-relaxed mb-4">Il est entendu que L'Association s'interdit toute activité d'ordre politique ou confessionnel.</p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE TROISIEME: SIEGE SOCIAL</h3>
          <p className="text-gray-600 leading-relaxed mb-4">Le siège social est sis au 6<sup>ème</sup> étage du Centre d'Affaires Allal Ben Abdallah, sis au 47 rue Allal Ben Abdallah (angle rue Mohamed Al Quori), 20000 Casablanca.</p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE QUATRIEME: DUREE</h3>
          <p className="text-gray-600 leading-relaxed mb-4">L'Association est constituée pour une durée illimitée.</p>

          <h2 className="text-2xl font-semibold mt-8 mb-6 text-gray-800">TITRE II Composition de l'Association</h2>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE CINQUIEME: COMPOSITION</h3>
          <p className="text-gray-600 leading-relaxed mb-4">L'Association se compose de deux types d'adhérents : adhérents titulaires (actifs et bienfaiteurs) et adhérents honoraires. Seuls peuvent être membres actifs et bienfaiteurs, les anciens élèves ayant satisfait aux examens de sortie de l'Ecole Nationale des Ponts et Chaussées de Paris dite Ecole des Ponts Paris Tech (ci-après dénommée, l'« Ecole ») et ayant obtenu le diplôme d'ingénieur civil des ponts et chaussées ou d'ingénieur des ponts et chaussées (ci-après dénommé, le « Diplôme »).</p>
          <p className="text-gray-600 leading-relaxed mb-4">La cotisation minimale annuelle pour les adhérents titulaires est de :</p>
          <ul className="list-disc pl-8 mb-4 text-gray-600">
              <li className="mb-2">100 Dirhams pour les anciens élèves ayant obtenu leur Diplôme depuis moins de 3 années.</li>
              <li className="mb-2">200 Dirhams pour les anciens élèves ayant obtenu leur Diplôme depuis plus de 3 années.</li>
          </ul>
          <p className="text-gray-600 leading-relaxed mb-4">Sont dispensés de la cotisation minimale annuelle les anciens élèves ayant obtenu leur Diplôme depuis moins d'une année.</p>
          <p className="text-gray-600 leading-relaxed mb-4">Pendant leur séjour à l'Ecole, les élèves ont la possibilité de s'inscrire à l'Association comme adhérents titulaires. Toutes les veuves et les veufs des camarades décédés sont membres honoraires de l'amicale. Le titre de membre honoraire peut être décerné sur présentation par le comité à l'Assemblée Générale délibérant à la majorité des trois quarts (3/4) des membres présents, des personnes qui rendent ou ont rendu des services au profit de l'Association.</p>
          <p className="text-gray-600 leading-relaxed mb-4">La qualité d'adhérent de l'association se perd :</p>
          <ul className="list-disc pl-8 mb-4 text-gray-600">
              <li className="mb-2">par démission ;</li>
              <li className="mb-2">par radiation prononcée par le comité pour le non-paiement de la cotisation ou pour motif grave ;</li>
              <li className="mb-2">par décès.</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-6 text-gray-800">TITRE III Administration et Fonctionnement</h2>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE SIXIEME: ADMINISTRATION</h3>
          <p className="text-gray-600 leading-relaxed mb-4">Sous réserve des dispositions des articles 8 et suivants ci-après, l'Association est administrée par un Comité de 13 membres dont un Président, élus par l'Assemblée Générale parmi les adhérents actifs et/ou bienfaiteurs pour une durée de deux ans. Le Président ne peut assurer plus de deux mandats successifs. Le reste des membres du Comité sont rééligibles à raison de la moitié tous les deux ans sans qu'aucun membre ne puisse dépasser deux mandats successifs.</p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE SEPTIEME: FONCTIONNEMENT</h3>
          <p className="text-gray-600 leading-relaxed mb-4">Le Comité sera composé de :</p>
          <ul className="list-disc pl-8 mb-4 text-gray-600">
              <li className="mb-2">Un Président</li>
              <li className="mb-2">Un Vice-Président</li>
              <li className="mb-2">Un Secrétaire Général</li>
              <li className="mb-2">Un Secrétaire Général Adjoint</li>
              <li className="mb-2">Un Trésorier</li>
              <li className="mb-2">Un Trésorier Adjoint</li>
              <li className="mb-2">Sept Assesseurs</li>
          </ul>
          <p className="text-gray-600 leading-relaxed mb-4">Le Président assure l'exécution des décisions du Comité et le fonctionnement régulier de l'Association qu'il représente en justice et dans tous les actes de la vie civile. Le Vice-Président a délégation de tous les pouvoirs du Président. Le Secrétaire Général intervient sur toutes les démarches administratives liées à la vie civile. Le Trésorier est tenu d'ouvrir un compte chèque postal ou un compte bancaire au nom de l'Association et tient la comptabilité de l'Association en effectuant toutes les dépenses approuvées par le Président et en procédant à l'appel des cotisations auprès des adhérents de l'Association et, le cas échéant, des subventions.</p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE SEPTIEME BIS : CONSEIL D'HONNEUR</h3>
          <p className="text-gray-600 leading-relaxed mb-4">Un conseil d'honneur est institué auprès du Comité de l'Association et aura pour mission :</p>
          <ul className="list-disc pl-8 mb-4 text-gray-600">
              <li className="mb-2">D'apporter conseil et orientation à la demande du Comité sur toutes les actions stratégiques que ce dernier serait amené à lancer entre deux assemblées générales.</li>
              <li className="mb-2">De soutenir le Comité dans la mise en œuvre des actions qui nécessitent une forte mobilisation interne ou externe.</li>
          </ul>
          <p className="text-gray-600 leading-relaxed mb-4">Les membres du conseil d'honneur sont nommés par l'Assemblée Générale, sur proposition du Comité parmi les membres titulaires ayant rendu des services notables à l'Amicale. Sont membres d'office du conseil d'honneur, les membres fondateurs de l'Association et les anciens présidents.</p>

          <h2 className="text-2xl font-semibold mt-8 mb-6 text-gray-800">TITRE IV Assemblée Générale</h2>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE HUITIEME: COMPOSITION</h3>
          <p className="text-gray-600 leading-relaxed mb-4">L'Assemblée Générale comprend les adhérents actifs et bienfaiteurs à jour de leur cotisation et faisant partie de l'Association depuis au moins 3 mois. Elle se réunit au moins une fois par an et chaque fois qu'elle est convoquée par le Comité ou sur demande du tiers au moins de ses membres. La convocation doit se faire au moins un mois avant la date fixée par lettre individuelle ou par voie de presse. Son ordre du jour est fixé par le Comité. L'Assemblée est présidée par le Président du Comité ou à défaut par le Vice-Président. Les fonctions de Secrétaire sont assurées par le Secrétaire Général, ou à défaut, par le Secrétaire Général Adjoint.</p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE NEUVIEME : DELIBERATIONS</h3>
          <p className="text-gray-600 leading-relaxed mb-4">Les délibérations sont prises à la majorité des voix des membres présents. En cas de partage, la voix du Président est prépondérante. Le vote par correspondance est admis. Pour délibérer valablement, l'Assemblée doit être composée de la moitié au moins des Membres titulaires. Si cette condition n'est pas remplie, l'Assemblée est convoquée à nouveau dans la forme prescrite à l'article 8 et dans sa seconde réunion, elle délibère valablement quel que soit le nombre de ses membres, mais seulement sur les objets inscrits à l'ordre du jour de la précédente réunion. Les délibérations de l'Assemblée sont constatées par des Procès-Verbaux, lesquels sont consignés sur un registre spécial.</p>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE DIXIEME: POUVOIRS</h3>
          <p className="text-gray-600 leading-relaxed mb-4">L'Assemblée Générale se prononce sur le rapport moral et sur le rapport financier établis par le Comité et délibère sur les questions qui sont portées à l'ordre du jour, y compris la modification des statuts.</p>

          <h2 className="text-2xl font-semibold mt-8 mb-6 text-gray-800">TITRE V : Ressources</h2>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE ONZIEME : RESSOURCES</h3>
          <p className="text-gray-600 leading-relaxed mb-4">Les ressources annuelles de l'Association se composent :</p>
          <ul className="list-disc pl-8 mb-4 text-gray-600">
              <li className="mb-2">des droits d'entrée et des cotisations versés par les adhérents ;</li>
              <li className="mb-2">des revenus, des biens ou valeurs qu'elle possède ;</li>
              <li className="mb-2">des subventions éventuelles des pouvoirs publics ;</li>
              <li className="mb-2">et de toute autre ressource qui ne soit pas contraire aux règles en vigueur.</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-6 text-gray-800">TITRE VI Dissolution</h2>

          <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">ARTICLE DOUZIEME : DISSOLUTION</h3>
          <p className="text-gray-600 leading-relaxed mb-4">La dissolution n'est prononcée qu'en vertu d'une décision prise par les deux tiers au moins des membres présents de l'Assemblée Générale convoquée à cet effet par le Comité. En cas de dissolution, l'Assemblée Générale désigne un ou plusieurs Commissaires chargés de la liquidation des biens de l'Association. Elle attribue l'actif net à un ou plusieurs établissements analogues publics ou reconnus d'utilité publique.</p>
        </div>
      </div>
    </PageLayout>
  );
};
