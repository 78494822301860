import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Presse } from "../../types/api-types";
import { createPresse, getPresses, deletePresse } from "../../services/presse.service";

export const AdminPresse: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [presses, setPresses] = useState<Presse[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    lien: '',
    date: ''
  });

  useEffect(() => {
    const fetchPresses = async () => {
      try {
        const { data, error } = await getPresses();
        if (!error && data) {
          setPresses(data as unknown as Presse[]);
        }
      } catch (error) {
        console.error('Error fetching presses:', error);
      }
    };

    fetchPresses();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { error } = await deletePresse(accessToken, id);
      
      if (!error) {
        setPresses(prev => prev.filter(presse => presse.id !== id));
      } else {
        console.error('Error deleting press item:', error);
      }
    } catch (error) {
      console.error('Error deleting press item:', error);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      const presseData = {
        description: formData.description,
        lien: formData.lien,
        date: formData.date
      };

      const { data, error } = await createPresse(accessToken, presseData);
      if (!error && data) {
        setPresses(prev => [...prev, data as unknown as Presse]);
        setShowForm(false);
      }
    } catch (error) {
      console.error('Error saving press item:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="tab-pane">
      <h2>Gestion de la Presse</h2>
      <button 
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
        onClick={() => setShowForm(true)}
      >
        + Ajouter un article de presse
      </button>
      
      {showForm && (
        <form className="admin-form" onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="lien">Lien</label>
            <input
              type="url"
              id="lien"
              name="lien"
              value={formData.lien}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="flex gap-2 mt-4">
            <button 
              type="submit" 
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 font-medium transition-colors"
            >
              Sauvegarder
            </button>
            <button 
              type="button" 
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 font-medium transition-colors"
              onClick={() => setShowForm(false)}
            >
              Annuler
            </button>
          </div>
        </form>
      )}

      <div className="items-list">
        {presses.map(presse => (
          <div key={presse.id} className="admin-item">
            <h3>{presse.description}</h3>
            <p className="presse-details">
              Date: {new Date(presse.date).toLocaleDateString()}
            </p>
            <a href={presse.lien} target="_blank" rel="noopener noreferrer" className="presse-link">
              Voir l'article
            </a>
            <button 
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 font-medium transition-colors"
              onClick={() => handleDelete(presse.id)}
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
